export default {
  props: {
    modelValue: {},
    list: {}, // prevent inheritence of list="" on element
  },
  data: function () {
    return {};
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      return attrs;
    },
  },
};
